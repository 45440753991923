import React, { Component } from 'react'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import chips from '../img/chip_stack.png'
import Form from 'react-bootstrap/Form'
import ThemeSelector from './ThemeSelector'
import { Row, Col } from 'reactstrap';

export class PlayerSettings extends Component {

    constructor(props){
        super(props);
        this.state = {
          maxPlayers: 8,
          bigBlind: 10,
          buyIn: 1000,
          tableName: "Poker Night Table",
          updating: "Update Settings"
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
      if(state.maxPlayers == null || state.bigBlind == null || state.buyIn == null || state.tableName == null) {
        return {
          maxPlayers: nextProps.maxPlayers,
          bigBlind: nextProps.bigBlind,
          buyIn: nextProps.buyIn,
          tableName: nextProps.tableName
        };
      } else if (!nextProps.isHost) {
        return {
          maxPlayers: nextProps.maxPlayers,
          bigBlind: nextProps.bigBlind,
          buyIn: nextProps.buyIn,
          tableName: nextProps.tableName
        };
      }
      return null;
    }

    componentDidMount = () => {
        this.setState({
          maxPlayers: this.props.maxPlayers,
          bigBlind: this.props.bigBlind,
          buyIn: this.props.buyIn,
          tableName: this.props.tableName,
          updating: "Update Settings"
        })
    }

    validateAndSetMaxPlayers(value) {
      if (isNaN(value) || value < 2 || value > 20){
        console.log("Invalid number, not setting value.")
      } else {
        this.setState({maxPlayers: value, updating: "Update Settings"})
      }
      console.log(this.state)
    }

    validateAndSetBigBlind(value) {
      if (isNaN(value) || value < 2 || value > 200 || value % 2 != 0 ){
        console.log("Invalid number, not setting value.")
      } else {
        this.setState({bigBlind: value, updating: "Update Settings"})
      }
      console.log(this.state)
    }

    validateAndSetBuyIn(value) {
      if (isNaN(value) || value < 10 || value > 10000 || value % 10 != 0){
        console.log("Invalid number, not setting value.")
      } else {
        this.setState({buyIn: value, updating: "Update Settings"})
      }
      console.log(this.state)
    }

    validateAndSetTableName(value) {
      if(value.match("^[a-zA-Z ]*$") != null){
        this.setState({tableName: value, updating: "Update Settings"});
      }
    }

    onSettingsChange = (tableId) => {
      console.log("Updating the Table Settings for Table " + tableId)
      this.setState({updating: "Updating..."})
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/table/' + tableId, {
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              action: 'update',
              hostUserId: this.props.hostUserId,
              maxPlayers: this.state.maxPlayers,
              tableName: this.state.tableName,
              buyIn: this.state.buyIn,
              bigBlind: this.state.bigBlind
          })
      })
          .then(this.setState({updating: "Updated!"}))

          // Log any errors
          .catch(error => console.log(error));
    };

    render() {
        return (
            <div>
                <h1 className="header-style">
                    SETTINGS:
                </h1>
                <div>
                    <Form>
                        <div className="custom-slider">
                            <Form.Group as={Row}>
                                <Form.Label column sm="2" className="slider-label">
                                    Max Players
                                </Form.Label>
                                <Col sm="7">
                                    <RangeSlider value={this.state.maxPlayers} onChange={changeEvent => this.validateAndSetMaxPlayers(changeEvent.target.value)}
                                      min={2} max={20} size="lg" variant="light" tooltip="auto" tooltipPlacement="bottom" disabled={!this.props.isHost}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Form.Control className="settings-textfield" type="number" pattern="[0-9]*" disabled={!this.props.isHost} value={this.state.maxPlayers} onChange={changeEvent => this.validateAndSetMaxPlayers(changeEvent.target.value)}/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="custom-slider">
                            <Form.Group as={Row}>
                                <Form.Label column sm="2" className="slider-label">
                                    Big Blind
                                </Form.Label>
                                <Col sm="7">
                                    <RangeSlider value={this.state.bigBlind} onChange={changeEvent => this.validateAndSetBigBlind(changeEvent.target.value)}
                                      min={2} max={200} size="lg" step={2} variant="light" tooltip="auto" tooltipPlacement="bottom" disabled={!this.props.isHost}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Form.Control className="settings-textfield" type="number" pattern="[0-9]*" disabled={!this.props.isHost} value={this.state.bigBlind} onChange={changeEvent => this.validateAndSetBigBlind(changeEvent.target.value)}/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="custom-slider">
                            <Form.Group as={Row}>
                                <Form.Label column sm="2" className="slider-label">
                                    Buy In
                                </Form.Label>
                                <Col sm="7">
                                    <RangeSlider value={this.state.buyIn} onChange={changeEvent => this.validateAndSetBuyIn(changeEvent.target.value)}
                                      min={10} max={10000} step={10} size="lg" variant="light" tooltip="auto" tooltipPlacement="bottom" disabled={!this.props.isHost}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Form.Control className="settings-textfield" type="number" pattern="[0-9]*" disabled={!this.props.isHost} value={this.state.buyIn} onChange={changeEvent => this.validateAndSetBuyIn(changeEvent.target.value)}/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="custom-slider">
                            <Form.Group as={Row}>
                                <Form.Label column sm="2" className="slider-label">
                                    Table Name
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control className="settings-textfield align-textbox-center" type="text" disabled={!this.props.isHost} value={this.state.tableName} onChange={changeEvent => this.validateAndSetTableName(changeEvent.target.value)}/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="custom-slider">
                          <ThemeSelector theme={this.props.theme} setTheme={this.props.setTheme}/>
                        </div>
                        <div className="custom-slider">
                            <button type="button" className="btn btn-outline-light btn-lg btn-block btn-bold" disabled={!this.props.isHost} value={this.props.isHost ? this.state.updating : "Only the host can modify settings."}
                                    onClick={() =>
                                        this.onSettingsChange(this.props.tableId)}>{this.state.updating}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

export default PlayerSettings;
