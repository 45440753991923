import React, { Component } from 'react';
import LoginHome from './LoginComponents/LoginHome';
import HostGameHome from './LoginComponents/HostGameHome';
import GameScreen from './GameComponents/GameScreen';
import CheatSheet from './GameComponents/CheatSheet';
import GenericInfoModal from './UserComponents/GenericInfoModal'
import logo from './img/RedPokerChip.png';
import backgroundImage from './img/GreenBackground.png';
import scrollingOverlay from './img/block_scrolling_menu_suits_small.png';
import scrollingOverlayWebp from './img/block_scrolling_menu_suits_small.webp';
import musicIcon from './img/musical-64.png';
import pizzaIcon from './img/pizza-64.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import FadeIn from 'react-fade-in';
import Cookies from 'universal-cookie';

const backgroundStyle = {
    zIndex: '-1',
    position: 'fixed',
    x: '0',
    y: '0',
    width: '100vw',
    height: '100vh',
    left: '0',
    top: '0'
}

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ws: null,
            hasJoinTableError: false,
            hasHostTableError: false,
            atTable: false,
            playerName: "",
            playerId: "",
            tableId: "",
            tableStateData: {},
            gameStateData: {},
            rejoining: false,
            userId: "",
            showMaxPlayersModal: false,
            showBlankNameModal: false,
            showBlankEmailModal: false,
            showGotKickedModal: false,
            showCheatSheet: false,
            disableButtons: false,
            chatOpen: false,
            width: window.innerWidth,
        };
    }

    componentWillMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };

    componentDidMount = () => {
        // TODO Check for playerID and TableID cookies
        let cookies = new Cookies()
        let tableIdCookie = cookies.get('tableId');
        let playerIdCookie = cookies.get('playerId');
        let userIdCookie = cookies.get('userId');
        console.log(playerIdCookie);
        console.log(tableIdCookie);

        if (playerIdCookie !== undefined && tableIdCookie !== undefined) {
            this.setState({
                rejoining: true,
                playerId: playerIdCookie,
                tableId: tableIdCookie,
                atTable: true,
                userId: userIdCookie,
            });
        }
    };

    processJoin(playerId, message, tableId, playerName) {
        console.log("Processing Join Table");
        let cookies = new Cookies();
        cookies.remove('userId')
        if (playerId !== undefined) {
            this.setState({
                tableId: tableId,
                playerName: playerName,
                playerId: playerId,
                atTable: true,
            });
        } else {
            console.log(message)
            this.setState({hasJoinTableError: true, atTable: false, showMaxPlayersModal: true})
        }
    }

    onJoinExistingTable = (playerName, tableId) => {
      var regexConst = new RegExp('^((?!(0))[0-9]{6})$')
      var tableIdRegex = /^((?!(0))[0-9]{6})$/g
      if (playerName.trim() == "") {
        this.setState({showBlankNameModal: true})
      } else if (!tableIdRegex.test(tableId)) {
        this.setState({showMaxPlayersModal: true})
      } else {
        // API call to join Table. Checks if table exists. If exists creates new player
        this.setState({disableButtons: true})
        fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/player', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: "create_player",
                playerName: playerName,
                tableId: tableId
            })
        })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            // ...then we update the users state
            .then(data => {
                this.processJoin(data.playerId, data.message, tableId, playerName)
              }
            )
            // Catch any errors we hit and update the app
            .catch(error => {
              this.setState({hasJoinTableError: true, atTable: false})
              this.setState({disableButtons: false})
            });
      }
    };

    processHost(tableId, message, playerName, playerEmail) {
        if (tableId !== undefined) {
            this.setState({userId: playerEmail})
            this.onJoinExistingTable(playerName, tableId)
        } else {
            console.log(message)
            this.setState({hasHostTableError: true, atTable: false})
        }
    }

    onHostNewGame = (playerName, playerEmail) => {
      if (playerName.trim() == "") {
        this.setState({showBlankNameModal: true})
      } else if (playerEmail.trim() == "") {
        this.setState({showBlankEmailModal: true})
      } else {
        this.setState({disableButtons: true})
        fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/table', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                playerName: playerName,
                hostUserId: playerEmail,
                maxPlayers: 8,
                tableName: "Poker Night",
                buyIn: 1000,
                bigBlind: 10,
            })
        })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            // ...then we update the users state
            .then(data =>
                this.processHost(data.tableId, data.message, playerName, playerEmail)
            )
            // Catch any errors we hit and update the app
            .catch(error => {
              this.setState({hasHostTableError: true, atTable: false})
              this.setState({disableButtons: false})
            });
      }
    };

    gotKicked = () => {
      this.leavingTableTasks()
      this.setState({showGotKickedModal: true})
    }

    leavingTableTasks = () => {
      this.setState({atTable: false, disableButtons: false})
      let cookies = new Cookies()
      let tableIdCookie = cookies.remove('tableId');
      let playerIdCookie = cookies.remove('playerId');
      let userIdCookie = cookies.remove('userId');
    }

    leaveTable = (leavingPermanently = true) => {
      // API call to leave Table. Permanent leave by hitting leave table, temp leave by sitting out.
      const fix_circular_reference = leavingPermanently ? "yes": "no"
      this.setState({disableButtons: false})
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/player/' + this.state.playerId, {
          method: 'DELETE',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            leavingTablePermanently: fix_circular_reference
          })
      })
          // We get the API response and receive data in JSON format...
          .then(response => response.json())
          // ...then we update the users state
          .then(data => {
              console.log(data)
              if (leavingPermanently) {
                this.leavingTableTasks()
              }
          })
          // Catch any errors we hit and update the app
          .catch(error => console.log(error));
    }

    kickPlayer = (playerId) => {
      // API call to kick player. straight up deletes that bitch
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/player/' + playerId, {
          method: 'DELETE',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            leavingTablePermanently: "yes"
          })
      })
          // We get the API response and receive data in JSON format...
          .then(response => response.json())
          // ...then we update the users state
          .then(data => {
              console.log(data)
          })
          // Catch any errors we hit and update the app
          .catch(error => console.log(error));
    }

    closeModal = () => {
      this.setState({
        showMaxPlayersModal: false,
        showBlankNameModal: false,
        showBlankEmailModal: false,
        showGotKickedModal: false
      });

    }

    closeCheatSheet = () => {
      this.setState({showCheatSheet: false});
    }

    openCheatSheet = () => {
      this.setState({showCheatSheet: true});
    }

    chatWasOpened = (chatState) => {
      this.setState({chatOpen: chatState});
    }

    render() {
        const { width } = this.state;
        const isMobileChat = width <= 450;
        const isMobile = width <= 576;
        if(this.state.atTable) {
            return (
                <div className="App" >
                    <div>
                        <img src={backgroundImage} className="backgroundStyle"/>
                        <picture draggable="false">
                          <source srcSet={scrollingOverlayWebp} type="image/webp"/>
                          <source srcSet={scrollingOverlay} type="image/png"/>
                          <img src={scrollingOverlay} className="scrollingBackgroundOverlay"/>
                        </picture>
                    </div>
                    <header className={`App-header ${this.state.chatOpen && isMobileChat ? "display-none" : ""}`}>
                            <button type="button" className="leave-table-button btn btn-outline-light btn-lg btn-bold white"
                                    onClick={this.leaveTable}>
                                 Leave Table
                            </button>
                            <h1 className="App-title">POKER NIGHT</h1>
                            <img src={logo} className="App-logo" alt="logo" />
                            <button type="button" className="cheat-sheet-button btn btn-outline-light btn-lg btn-bold white"
                                    onClick={this.openCheatSheet}>
                                 CheatSheet
                            </button>
                            <a className="small-icon-button btn btn-outline-light btn-lg btn-bold white" href="https://www.dominos.com/en/" target="_blank" role="button"><img src={pizzaIcon} className="small-icon-button-image"/></a>
                            <a className="small-icon-button btn btn-outline-light btn-lg btn-bold white" href="https://www.youtube.com/watch?v=7hx4gdlfamo" target="_blank" role="button"><img src={musicIcon} className="small-icon-button-image"/></a>
                    </header>
                    <div className="backgroundImagePoker zoom-out">
                        <div className="container joinCode">
                            <GameScreen playerId={this.state.playerId}
                                tableId={this.state.tableId}
                                rejoining={this.state.rejoining}
                                leaveTable={this.leaveTable}
                                userId={this.state.userId}
                                kickPlayer={this.kickPlayer}
                                gotKicked={this.gotKicked}
                                chatOpen={this.chatWasOpened}
                                isMobile={isMobile}/>
                        </div>
                    </div>
                    <footer className={`App-footer footer-text-padding ${this.state.chatOpen && isMobileChat ? "display-none" : ""}`}>
                      <span className="left-floatyboi">
                        <h5 className="inline">© 2020 MNM Technologies LLC</h5>
                        <span className="donate-button-spacing">
                          <a className="btn btn-outline-light btn-lg btn-block btn-bold inline" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=QG53BZW522Q96&source=url" target="_blank" role="button">DONATE</a>
                        </span>
                      </span>
                    </footer>
                    <CheatSheet showCheatSheet={this.state.showCheatSheet} closeModal={this.closeCheatSheet}/>
                    <GenericInfoModal showModal={this.state.showMaxPlayersModal} modalTitle={"Sorry fam..."} modalHeader={"This table has already reached the host's player limit."} closeModal={this.closeModal}/>
                </div>
            );
        } else {
            return (
                <div className="App" >
                    <div>
                        <img src={backgroundImage} className="backgroundStyle"/>
                        <picture draggable="false">
                          <source srcSet={scrollingOverlayWebp} type="image/webp"/>
                          <source srcSet={scrollingOverlay} type="image/png"/>
                          <img src={scrollingOverlay} className="scrollingBackgroundOverlay"/>
                        </picture>
                    </div>
                    <header className="App-header">
                        <h1 className="App-title">POKER NIGHT</h1>
                        <img src={logo} className="App-logo" alt="logo" />
                    </header>
                    <div className="backgroundImagePoker row">
                        <div className="container col-sm joinContainer">
                            <FadeIn transitionDuration="1000">
                              <LoginHome onJoinTable={this.onJoinExistingTable} disableButtons={this.state.disableButtons}/>
                            </FadeIn>
                        </div>
                        <div className="container col-sm hostContainer">
                            <FadeIn transitionDuration="1000">
                                <HostGameHome onHostGame={this.onHostNewGame} disableButtons={this.state.disableButtons}/>
                            </FadeIn>
                        </div>
                    </div>
                    <footer className="App-footer">
                        <h5 className="inline">© 2020 MNM Technologies LLC</h5>
                        <span className="donate-button-spacing">
                          <a className="btn btn-outline-light btn-lg btn-block btn-bold inline" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=QG53BZW522Q96&source=url" target="_blank" role="button">DONATE</a>
                        </span>
                    </footer>
                    <GenericInfoModal showModal={this.state.showMaxPlayersModal} modalTitle={"Sorry fam..."} modalHeader={"This table either doesn't exist, or has already reached the host's player limit."} closeModal={this.closeModal}/>
                    <GenericInfoModal showModal={this.state.showBlankNameModal} modalTitle={"Sorry fam..."} modalHeader={"Please enter a valid player name."} closeModal={this.closeModal}/>
                    <GenericInfoModal showModal={this.state.showBlankEmailModal} modalTitle={"Sorry fam..."} modalHeader={"Please enter a valid player email."} closeModal={this.closeModal}/>
                    <GenericInfoModal showModal={this.state.showGotKickedModal} modalTitle={"Sorry fam..."} modalHeader={"You were kicked from the table."} closeModal={this.closeModal}/>
                </div>
            );
        }
    }
}

export default App;
