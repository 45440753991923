import React, { Component } from 'react'
import {Animated} from "react-animated-css";

class GameStats extends Component {

    getDealerName() {
      if ((this.props.myPlayerInfo?.isCurrentPlayer || false) == true) {
        return this.props.myPlayerInfo.playerName;
      } else {
        for (const [key, value] of Object.entries(this.props.otherPlayers)) {
          if (value.isCurrentPlayer) {
            return value.playerName;
          }
        }
      }
      return null;
    }

    getRoundText() {
      switch(this.props.currentRound) {
        case 0:
          return "Pre-Flop"
        case 1:
          return "Flop"
        case 2:
          return "Turn"
        case 3:
          return "River"
        case 4:
          return "Game Over"
      }
    }

    render() {
        return (
            <div className="game-stat-border">
                <div>
                    <h2 className="header-style game-state-header">Game Stats</h2>
                </div>
                <div className="row no-gutters smol-horizontal-padding">
                    <h3 className="align-left game-state-label col no-gutters">Round:</h3>
                    <h3 className="align-right normal-font-weight game-state-label col no-gutters"><b>{this.getRoundText()}</b></h3>
                </div>
                <div className="row no-gutters smol-horizontal-padding">
                    <h3 className="align-left game-state-label col no-gutters">Total Pot:</h3>
                    <h3 className="align-right normal-font-weight game-state-label col no-gutters"><b>${this.props.totalPot}</b></h3>
                </div>
                <div className="row no-gutters smol-horizontal-padding">
                    <h3 className="align-left game-state-label col no-gutters">Table Bet:</h3>
                    <h3 className="align-right normal-font-weight game-state-label col no-gutters"><b>${this.props.currentTableBet}</b></h3>
                </div>
                <div className="row no-gutters smol-horizontal-padding">
                    <h3 className="align-left game-state-label col no-gutters">Your Bet:</h3>
                    <h3 className="align-right normal-font-weight game-state-label col no-gutters"><b>{this.props.myPlayerInfo != null ? `$${this.props.myPlayerInfo.bet}` : "N/A"}</b></h3>
                </div>
                <div className="row no-gutters smol-horizontal-padding">
                    <h3 className="align-left game-state-label col no-gutters">Dealer:</h3>
                    <h3 className="align-right normal-font-weight game-state-label col no-gutters"><b>{this.getDealerName()}</b></h3>
                </div>
            </div>
        )
    }
}

export default GameStats;
