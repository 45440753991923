import React, { Component } from 'react'
import {Animated} from "react-animated-css";
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'reactstrap';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import GenericInfoModal from '../UserComponents/GenericInfoModal'

export default class Actions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            raiseAmt: 0,
            minRaiseAmt: props.minRaiseAmount,
            showModal: false,
            modalText: "",
            disabledButtons: false,
        };
    }

    static getDerivedStateFromProps(nextProps, state) {
      var returnState = {}
      returnState['minRaiseAmt'] = nextProps.minRaiseAmount
      if(state.raiseAmt == null || state.raiseAmt == 0) {
        returnState['raiseAmt'] = nextProps.minRaiseAmount
      }
      return returnState;
    }

    componentDidMount = () => {
        this.setState({
          raiseAmt: this.props.minRaiseAmount,
          minRaiseAmt: this.props.minRaiseAmount,
          showModal: false,
          modalText: ""
        });
    }

    getCallOrCheckText() {
      if ((this.props.myPlayerInfo?.bet || 0) == this.props.currentTableBet) {
          return "check";
      } else {
          return "call";
      }
    }

    callOrCheck() {
      this.props.disableActionButtons();
      let method = this.getCallOrCheckText()
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/game/playerAction', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                      "gameId": this.props.gameId,
                      "playerId": this.props.playerId,
                      "action": method
                  })
          })
          // We get the API response and receive data in JSON format...
          .then(response => response.json())
          .then(data => {
              console.log(data)
              if (data?.message == "Player does not have enough chips to call.") {
                this.setState({
                  showModal: true,
                  modalText: "You don't have enough chips to call. Fold or go all-in!.",
                });
                this.props.enableActionButtons();
              } else if (data?.message?.endsWith('can not check.')) {
                this.setState({
                  showModal: true,
                  modalText: "You can't check right now. Honestly, you shouldn't have been able to click this button.",
                });
                this.props.enableActionButtons();
              }
          })
          .catch(error => console.log(error));
    }

    allIn() {
      this.props.disableActionButtons();
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/game/playerAction', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                        "gameId": this.props.gameId,
                        "playerId": this.props.playerId,
                        "action": "allin"
                    })
            })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            .then(data => {
              console.log(data)
              if (data?.status !== 200) {
                this.props.enableActionButtons();
              }
            })
            .catch(error => console.log(error));
    }

    raise(raiseAmt) {
      this.props.disableActionButtons();
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/game/playerAction', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                        "gameId": this.props.gameId,
                        "playerId": this.props.playerId,
                        "value": Number(raiseAmt),
                        "action": "raise"
                    })
            })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data?.message.includes('tried to raise, but they did not reach the minimum raise amount')) {
                  this.setState({
                    showModal: true,
                    modalText: `You didn't meet the minimum raise amount. It's currently $${this.state.minRaiseAmt}.`,
                  })
                  this.props.enableActionButtons();
                } else if (data?.message?.includes('can not raise to')) {
                  this.setState({
                    showModal: true,
                    modalText: "You don't have enough chips to raise to that value. Can't sneak one by us.",
                  })
                  this.props.enableActionButtons();
                }
            })
            .catch(error => console.log(error));
    }

    fold() {
      this.props.disableActionButtons();
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/game/playerAction', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                        "gameId": this.props.gameId,
                        "playerId": this.props.playerId,
                        "action": "fold"
                    })
            })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            .then(data => {
              if (data?.status !== 200) {
                this.props.enableActionButtons();
              }
              console.log(data)
            })
            .catch(error => console.log(error));
    }

    onRaiseAmtChanged(value) {
      if (isNaN(value)) {
        console.log("not a number.")
      } else {
        this.setState({ raiseAmt: value });
      }
    }

    closeModal = () => {
      this.setState({showModal: false});
    }

    render() {
            return (
                <Animated animationIn="fadeInUp" animationOut="fadeOutDown" animationInDuration={1000} animationInDelay={500} animationOutDuration={1000} isVisible={this.props.myPlayerInfo?.isCurrentPlayer || false}>
                    <div>
                        <span>
                            <form className="col">
                                <button type="button" className="btn btn-outline-light btn-xlg action-btn" disabled={this.props.disabledButtons}
                                    onClick={() => this.callOrCheck()}>{this.getCallOrCheckText().replace("c", "C")}<span className="normal-font-weight btn-xlg-smaller-font">{this.getCallOrCheckText() == "call" ? ` ($${this.props.currentTableBet - this.props.myBet})` : ""}</span></button>
                                <button type="button" className={`btn btn-outline-light btn-xlg action-btn ${this.getCallOrCheckText() == "call" ? "" : ""}`} disabled={this.props.disabledButtons}
                                    onClick={() => this.raise(this.state.raiseAmt)}>
                                    {this.getCallOrCheckText() == "call"
                                      ? <div className="btn-xlg-shrinko-mode">Call & Raise<span className="normal-font-weight btn-xlg-smaller-font"> (${this.props.currentTableBet - this.props.myBet} + ${this.state.raiseAmt})</span></div>
                                      : <div>Raise<span className="normal-font-weight btn-xlg-smaller-font"> (${this.state.raiseAmt})</span></div>
                                    }
                                </button>
                                <button type="button" className="btn btn-outline-light btn-xlg action-btn" disabled={this.props.disabledButtons}
                                    onClick={() => this.allIn()}>ALL IN</button>
                                <button type="button" className="btn btn-outline-light btn-xlg action-btn" disabled={this.props.disabledButtons}
                                    onClick={() => this.fold()}>Fold</button>
                            </form>
                            <div className="center-me bottom-padding-more">
                              <Form.Group as={Row} className="center-me">
                                  <Form.Label column sm="2" className="slider-label">
                                      Raise Amount
                                  </Form.Label>
                                  <Col sm="7">
                                      <RangeSlider value={this.state.raiseAmt} onChange={changeEvent => this.onRaiseAmtChanged(changeEvent.target.value)}
                                        min={this.state.minRaiseAmt} max={this.props.myPlayerInfo?.chipsValue || 0} size="lg" step={10} variant="light" tooltip="auto" tooltipPlacement="bottom"
                                      />
                                  </Col>
                                  <Col sm="3">
                                      <Form.Control type="number" className="settings-textfield" id="raiseAmt" value={this.state.raiseAmt} onChange={changeEvent => this.onRaiseAmtChanged(changeEvent.target.value)}/>
                                  </Col>
                              </Form.Group>
                            </div>
                        </span>
                    </div>
                    <GenericInfoModal showModal={this.state.showModal} modalTitle={"Sorry fam..."} modalHeader={this.state.modalText} closeModal={this.closeModal}/>
                </Animated>
        )
    }
}
