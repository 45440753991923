import React, { Component } from 'react'
import blackChip from '../img/black_chip.png';
import fireGif from '../img/fire2.gif'; /*credits: https://gifimage.net/fire-animated-gif-transparent-11/*/
import {Animated} from "react-animated-css";

const imageBox = {
    position: 'relative',
    display: 'inline-block' /* Make the width of box same as image */
};

const imageText = {
    'position': 'absolute',
    'z-index': '999',
    'margin': '0 auto',
    'left': '0',
    'right': '0',
    'top': '40%', /* Adjust this value to move the positioned div up and down */
    'text-align': 'center',
    'width': '60%', /* Set the width of the positioned div */
};

const handStyle = {
    display: 'grid inline-block',
    backgroundColor: 'transparent',
    position: 'center',
    padding: '10px',
    class: 'border-dark'
};

const chipImgStyle = {
    height: '7%',
    width: '7%'
};

const cardImgStyle = {
    height: '6%',
    width: '6%',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "5px"
};

export default class Hand extends Component {

    constructor(props) {
      super(props)
      this.state = {
        hasFolded: false,
        allIn: false,
        isCurrentPlayer: false
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.myPlayerInfo == null) {
        return {hasFolded: false, allIn: false}
      }
      if (nextProps.myPlayerInfo['hasFolded'] != null) {
        if(nextProps.myPlayerInfo['hasFolded'] != prevState.hasFolded){
          return {hasFolded: nextProps.myPlayerInfo['hasFolded']};
        }
      }
      if (nextProps.myPlayerInfo['allIn'] != null) {
        if(nextProps.myPlayerInfo['allIn'] != prevState.allIn){
          return {allIn: nextProps.myPlayerInfo['allIn']};
        }
      }
      if (nextProps.myPlayerInfo['isCurrentPlayer'] != null) {
          return {isCurrentPlayer: nextProps.myPlayerInfo['isCurrentPlayer']};
      }
      return null;
    }

    render() {
        const cardOnePath =  require('../img/cards/' + this.props.cards[0].rank + this.props.cards[0].suit + '.png');
        const cardTwoPath =  require('../img/cards/' + this.props.cards[1].rank + this.props.cards[1].suit + '.png');

        return (
            <div className={this.state.isCurrentPlayer ? "currentPlayerStyle" : "notCurrentPlayerStyle"}>
                <div style={handStyle} className="relative-position">
                    <div className={`${this.state.hasFolded || this.props.isActive == false ? "folded" : ""}`}>
                        <img className="handCardStyle" src ={cardOnePath} />
                        <img className="handCardStyle" src ={cardTwoPath} />
                        <img src={blackChip} alt="chipsVal" style={chipImgStyle}/>
                        <img src={fireGif} className={`all-in-overlay all-in-overlay-1 all-in-overlay-hand ${this.state.allIn ? "opacity-1" : "opacity-0"}`} />
                    </div>
                    <div>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} animationInDelay={1000} isVisible={this.state.hasFolded}>
                            <p className="folded-text folded-text-hand">FOLDED</p>
                        </Animated>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} animationInDelay={1000} isVisible={this.state.allIn}>
                            <p className="all-in-text all-in-text-hand">ALL IN</p>
                        </Animated>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} animationInDelay={1000} isVisible={this.props.isActive == false}>
                            <p className="all-in-text all-in-text-hand">SITTING OUT...</p>
                        </Animated>
                    </div>
                </div>
                <div className={imageBox}>
                    <div className={imageText}>
                        <h2 className={`money-styling ${ this.props.chipsValue < this.props.buyIn ? "red" : "green"}`}>${this.props.chipsValue}</h2>
                    </div>
                </div>
            </div>
        )
    }
}
