import React, { Component } from 'react'
import {Animated} from "react-animated-css";

class DisplayFiveCards extends Component {

    constructor(props) {
      super(props)
      this.state = {
        theme: "",
        isCheatSheet: ""
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      var newState = {}

      if(prevState.theme != nextProps.theme) {
        newState['theme'] = nextProps.theme;
      }
      if(nextProps.isCheatSheet != null) {
        newState['isCheatSheet'] = nextProps.isCheatSheet;
      }
      return newState;
    }

    render() {
        return (
            <div className={`displayFiveContainerStyle`}>
                {this.props.cards.map((card, index) =>{
                    const path = require('../img/cards/' + card.rank + card.suit + ((card.rank == 'H') ? this.state.theme : "") + '.png')
                      return <div className="flexyboi"><img src={path} className="DisplayFiveCardStyle" /></div>
                })}
            </div>
        )
    }
}

export default DisplayFiveCards;
