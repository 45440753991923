import React, { Component } from 'react';
import '../App.css';
import logo from '../img/RedPokerChip.png';
import {Launcher} from 'react-chat-window-poker'

class SmackTalk extends Component {

    constructor(props){
        super(props);
        this.state = {isOpen:false};
    }

    _onMessageWasSent(message) {
      message.author = this.props.me
      console.log("Sending a message in chat for table " + this.props.tableId)
      fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/table/' + this.props.tableId, {
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              action: 'sendMessage',
              message: message
          })
      })
          // Log any errors
          .catch(error => console.log(error));
    };

    _sendMessage(text) {
      console.log(text)
    };

    _handleClick() {
        this.setState({
          isOpen: !this.state.isOpen,
          newMessagesCount: 0
        });
        this.props.chatOpen(!this.state.isOpen)
      }

    render() {
        return (<div>
          <Launcher
            agentProfile={{
              teamName: 'Smack Talk',
              imageUrl: {logo}
            }}
            me={this.props.me}
            onMessageWasSent={this._onMessageWasSent.bind(this)}
            messageList={this.props.messageList}
            handleClick={this._handleClick.bind(this)}
            isOpen={this.state.isOpen}
            showEmoji
          />
        </div>)
      }
}

export default SmackTalk;
