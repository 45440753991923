import React, { Component } from 'react'
import blackChip from '../img/black_chip.png';

export default class JoinCode extends Component {
    render() {
        return (
            <div>
                <h1 className={`header-style ${this.props.tableStatus == "playing" ? "align-left" : ""}`}>
                    TABLE CODE:  <span className="badge badge-light no-text-shadow">{this.props.tableId}</span>
                </h1>
            </div>
        )
    }
}
