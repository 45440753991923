import React, { Component } from 'react'
import {Animated} from "react-animated-css";
import DisplayFiveCards from './DisplayFiveCards'
import { Modal, Button } from 'react-bootstrap'

// Haven't written this yet but it's for showing n00bs the hand rankings
class CheatSheet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showCheatSheet: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.showCheatSheet != nextProps.showCheatSheet) {
      return {showCheatSheet: nextProps.showCheatSheet};
    }
    return null;
  }

  row1cards() {
    return [
        {rank: "10", suit: "S"},
        {rank: "J", suit: "S"},
        {rank: "Q", suit: "S"},
        {rank: "K", suit: "S"},
        {rank: "A", suit: "S"},
    ]
  }

  row2cards() {
    return [
        {rank: "2", suit: "D"},
        {rank: "3", suit: "D"},
        {rank: "4", suit: "D"},
        {rank: "5", suit: "D"},
        {rank: "6", suit: "D"},
    ]
  }

  row3cards() {
    return [
        {rank: "A", suit: "S"},
        {rank: "A", suit: "D"},
        {rank: "A", suit: "C"},
        {rank: "A", suit: "H"},
        {rank: "H", suit: "H"},
    ]
  }

  row4cards() {
    return [
        {rank: "Q", suit: "H"},
        {rank: "Q", suit: "D"},
        {rank: "Q", suit: "S"},
        {rank: "J", suit: "D"},
        {rank: "J", suit: "C"},
    ]
  }

  row5cards() {
    return [
        {rank: "2", suit: "H"},
        {rank: "10", suit: "H"},
        {rank: "A", suit: "H"},
        {rank: "7", suit: "H"},
        {rank: "5", suit: "H"},
    ]
  }

  row6cards() {
    return [
        {rank: "2", suit: "S"},
        {rank: "3", suit: "D"},
        {rank: "4", suit: "C"},
        {rank: "5", suit: "H"},
        {rank: "6", suit: "S"},
    ]
  }

  row7cards() {
    return [
        {rank: "7", suit: "H"},
        {rank: "7", suit: "D"},
        {rank: "7", suit: "S"},
        {rank: "H", suit: "H"},
        {rank: "H", suit: "H"},
    ]
  }

  row8cards() {
    return [
        {rank: "Q", suit: "H"},
        {rank: "Q", suit: "C"},
        {rank: "K", suit: "D"},
        {rank: "K", suit: "S"},
        {rank: "H", suit: "H"},
    ]
  }

  row9cards() {
    return [
        {rank: "A", suit: "H"},
        {rank: "A", suit: "D"},
        {rank: "H", suit: "H"},
        {rank: "H", suit: "H"},
        {rank: "H", suit: "H"},
    ]
  }

  row10cards() {
    return [
        {rank: "A", suit: "S"},
        {rank: "H", suit: "H"},
        {rank: "H", suit: "H"},
        {rank: "H", suit: "H"},
        {rank: "H", suit: "H"},
    ]
  }

  render() {
    return (
      <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showCheatSheet}
          animation={true}
          style={{ opacity: 1 }}
      >
          <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                  <h1 className="modal-title-small">Cheat Sheet</h1>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <h1 className="modal-main-header">{this.props.modalHeader || "List of Hand Ranks"}</h1>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Royal Flush</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row1cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Straight Flush</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row2cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Four of a Kind (Quads)</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row3cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Full House (Boat)</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row4cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Flush</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row5cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Straight</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row6cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Three of a Kind (Trips)</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row7cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">Two Pair</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row8cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">One Pair</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row9cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2 align-center flexyHandName">
                      <h2 className="modal-main-header">High Card</h2>
                  </div>
                  <div className="col-10 align-left">
                      <DisplayFiveCards cards={this.row10cards()} theme="" isCheatSheet="-cheatsheet"/>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="outline-dark" size="lg" className="endgame-modal-button" onClick={() => this.props.closeModal()}>Close</Button>
          </Modal.Footer>
      </Modal>
    )
  }
}

export default CheatSheet;
