import React, { Component } from 'react'
import {Animated} from "react-animated-css";

const riverStyle = {
    display: 'flex',
    backgroundColor: 'transparent',
    position: 'center',
    class: 'border-dark',
    alignItems: 'center',
    paddingLeft: '4em',
    paddingRight: '4em',
    boxSizing: 'border-box'
}

class River extends Component {

    constructor(props) {
      super(props)
      this.state = {
        flopOneFlipped: false,
        flopTwoFlipped: false,
        flopThreeFlipped: false,
        turnFlipped: false,
        riverFlipped: false,
        theme: "",
        isCheatSheet: ""
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      var newState = {}
      if (prevState.flopOneFlipped == false && nextProps.cards[0].rank != 'H') {
        newState['flopOneFlipped'] = true;
        newState['flopTwoFlipped'] = true;
        newState['flopThreeFlipped'] = true;
      } else if (prevState.flopOneFlipped == true && nextProps.cards[0].rank == 'H') {
        newState['flopOneFlipped'] = false;
        newState['flopTwoFlipped'] = false;
        newState['flopThreeFlipped'] = false;
      }

      if (prevState.turnFlipped == false && nextProps.cards[3].rank != 'H') {
        newState['turnFlipped'] = true;
      } else if (prevState.turnFlipped == true && nextProps.cards[3].rank == 'H') {
        newState['turnFlipped'] = false;
      }

      if (prevState.riverFlipped == false && nextProps.cards[4].rank != 'H') {
        newState['riverFlipped'] = true;
      } else if (prevState.riverFlipped == true && nextProps.cards[4].rank == 'H') {
        newState['riverFlipped'] = false;
      }

      if(prevState.theme != nextProps.theme) {
        newState['theme'] = nextProps.theme;
      }
      if(nextProps.isCheatSheet != null) {
        newState['isCheatSheet'] = nextProps.isCheatSheet;
      }
      return newState;
    }

    render() {
        return (
            <div className="riverContainerStyle">
                {this.props.cards.map((card, index) =>{
                    const path = require('../img/cards/' + card.rank + card.suit + '.png')
                    const pathCardBack = require(`../img/cards/HH${this.state.theme}.png`)
                      return <div className="flexyboi"><div className={`card-flip-container ${((index == 0 && this.state.flopOneFlipped == true)||(index == 1 && this.state.flopTwoFlipped == true)||(index == 2 && this.state.flopThreeFlipped == true)||(index == 3 && this.state.turnFlipped == true)||(index == 4 && this.state.riverFlipped == true)) ? `cardflip${this.state.isCheatSheet}`: ""}`}><img src={pathCardBack} className="riverCardStyle" /><img src={path} className="almostRiverCardStyle"/><img src={path} className="riverCardStyle riverCardStyle-back" /></div></div>
                })}
            </div>
        )
    }
}

export default River;
