import React, { Component } from 'react'
import {Animated} from "react-animated-css";
import clinkSound from '../sounds/chips.mp3';

//gets passed in potValue, bigBlind, and buyIn
class Pot extends Component {

    constructor(props) {
      super(props)
      this.state = {
        potValue: 0,
        numberOfChips: 0,
        changeValue: 0,
        showChange: false
      }
      this.audio = new Audio(clinkSound);
    }

    componentWillMount() {
      this.setState({
        potValue: 0,
        numberOfChips: 0,
        changeValue: 0,
        showChange: false
      })
      setInterval(this.setState({showChange: false}), 20000);
    }

    componentDidUpdate(prevProps, prevState) {
      console.log("we def get here")
      console.log(prevState)
      if(prevState.showChange) {
        console.log("do we get here")
        var promise = this.audio.play();
        if (promise !== undefined) {
          promise.catch(error => {
              console.log("fuck safari")
              console.log(error)
            });
        }
        setTimeout(this.setState({showChange: false}), 10000);
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      var newState = {}
      if (nextProps.potValue != prevState.potValue) {
        newState['potValue'] = nextProps.potValue;
        newState['changeValue'] = nextProps.potValue - prevState.potValue;
        let initialChips = Math.floor(nextProps.potValue / nextProps.bigBlind);
        newState['numberOfChips'] = (initialChips > 13) ? 13 : initialChips;
        if (nextProps.potValue != 0) {
          newState['showChange'] = true;
        }
      }
      return newState;
    }

    render() {

      const potImage =  require('../img/chips/chips-' + this.state.numberOfChips + '.png');

      return (
        <div className="pot">
          <Animated animationIn="flipInX" animationOut="flipOutX" animationInDuration={1000} animationOutDuration={1000} isVisible={this.state.showChange && this.state.changeValue > 0} className="z10">
            <div className="pot-change-window">
            +${this.state.changeValue}
            </div>
          </Animated>
          <img src={potImage} className="pot-image"/>
          <div className="pot-value">${this.state.potValue}</div>
        </div>
      )
    }
}

export default Pot;
