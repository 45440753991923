import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';

export class LoginHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playerName: "",
            playerEmail: "",
        };
    }

    onPlayerNameChanged = (e) => {
        this.setState({ playerName: e.target.value });
    }

    onPlayerEmailChanged = (e) => {
        this.setState({ playerEmail: e.target.value });
    }

    render() {
        const responseGoogle = (response) => {
            console.log(response);
            var res = response.profileObj;
            console.log(res);
            debugger;
            this.signup(response);
        }
        return (
            <div>
                <form className="col">
                    <div className="form-group form-group-lg">
                        <input type="email" className="form-control textfield-box-shadow" id="playerEmail"
                               onChange={this.onPlayerEmailChanged} placeholder="Email Address"/>
                    </div>
                    <div className="form-group form-group-lg">
                        <input type="text" className="form-control textfield-box-shadow" id="name"
                               onChange={this.onPlayerNameChanged} placeholder="Player Name"/>
                    </div>
                    <button type="button" className="btn btn-outline-light btn-xlg btn-block" disabled={this.props.disableButtons}
                            onClick={() =>
                                this.props.onHostGame(
                                    this.state.playerName,
                                    this.state.playerEmail)}>
                        Host a Poker Game
                    </button>
                    <br/><br/><br/><br/>
                </form>
            </div>
        )
    }
}
export default LoginHome
