import React, { Component } from 'react'
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'axios'
export class LoginHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: "",
            playerName: ""
        };
    }

    onPlayerNameChanged = (e) => {
        this.setState({ playerName: e.target.value });
    }

    onTableIdChanged = (e) => {
        this.setState({ tableId: e.target.value.trim() });
    }

    signup(res) {
        const googleresponse = {
            Name: res.profileObj.name,
            email: res.profileObj.email,
            token: res.googleId,
            Image: res.profileObj.imageUrl,
            ProviderId: 'Google'
        };
        debugger;
        axios.post('http://localhost:60200/Api/Login/SocialmediaData', googleresponse)
            .then((result) => {
                let responseJson = result;
                sessionStorage.setItem("userData", JSON.stringify(result));
                this.props.history.push('/user-home')
            });
    };
    render() {
        const responseGoogle = (response) => {
            console.log(response);
            var res = response.profileObj;
            console.log(res);
            debugger;
            this.signup(response);
        }
        return (
            <div>
                <form className="col">
                    <div className="form-group form-group-lg">
                        <input type="text" className="form-control textfield-box-shadow" id="tableId"
                              onChange={this.onTableIdChanged} placeholder="Table Code (Ex: 111111)"/>
                    </div>
                    <div className="form-group form-group-lg">
                        <input type="text" className="form-control textfield-box-shadow" id="name"
                              onChange={this.onPlayerNameChanged} placeholder="Player Name"/>
                    </div>
                    <button type="button" className="btn btn-outline-light btn-xlg btn-block" disabled={this.props.disableButtons}
                        onClick={() =>
                            this.props.onJoinTable(
                                this.state.playerName,
                                this.state.tableId)}>
                        Join a Poker Game
                    </button>
                </form>
            </div>
        )
    }
}
export default LoginHome
