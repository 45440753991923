import React, { Component } from 'react'
import OpponentHand from '../GameComponents/OpponentHand';
import {Animated} from "react-animated-css";

const opponentsStyle = {
    display: 'grid inline-block',
    backgroundColor: 'transparent',
    position: 'center',
    paddingBottom: '10px',
    paddingTop: "-10px",
    class: 'border-dark',
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content'
}

const dummyHand = [
    {rank: "H", suit: "H"},
    {rank: "H", suit: "H"},
];

class Opponents extends Component {

    constructor(props) {
      super(props)
      this.state = {
        withinEndGameModal: false
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.withinEndGameModal != null) {
        if (nextProps.withinEndGameModal != prevState.withinEndGameModal) {
          return {withinEndGameModal: nextProps.withinEndGameModal}
        }
      }
      return null;
    }

    getChipsValue(playerId) {
        if (this.props.playerInfo[playerId] == undefined) {
            return(0);
        } else {
            return(this.props.playerInfo[playerId]['chipsValue']);
        }
    }

    getAnimationDelay(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
          return(1000);
      } else {
          return((this.props.playerInfo[playerId]['order'] + 1) * 1000);
      }
    }

    getPlayerName(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
          return("");
      } else {
          return(this.props.playerInfo[playerId]['playerName']);
      }
    }

    getFoldedStatus(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
        return false;
      } else {
        return(this.props.playerInfo[playerId]['hasFolded']);
      }
    }

    getAllInStatus(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
        return false;
      } else {
        return(this.props.playerInfo[playerId]['allIn']);
      }
    }

    getBet(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
        return 0;
      } else {
        return(this.props.playerInfo[playerId]['bet']);
      }
    }

    getCurrentPlayerStatus(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
        return false;
      } else {
        return(this.props.playerInfo[playerId]['isCurrentPlayer']);
      }
    }

    getHand(playerId) {
      if (this.props.playerInfo[playerId] == undefined) {
        return 0;
      } else {
        let cardOne = this.props.playerInfo[playerId]['hand'][0].split("-")
        let cardTwo = this.props.playerInfo[playerId]['hand'][1].split("-")
        return [
            {rank: cardOne[0], suit: cardOne[1]},
            {rank: cardTwo[0], suit: cardTwo[1]},
        ]
      }
    }

    getPlayerReady(playerId) {
      if (this.props.playerList == undefined) {
        return true;
      } else {
        for (const player of this.props.playerList) {
          if (player.playerId == playerId) {
            return player.ready
          }
        }
      }
      return true;
    }

    render() {
        return (
            <div style={opponentsStyle} className={this.props.withinEndGameModal ? "opponents-endgame" : ""}>
                {this.props.order.map(playerId =>{
                    if (playerId != this.props.playerId) {
                        let chipsValue = this.getChipsValue(playerId)
                        let animationDelay = this.getAnimationDelay(playerId)
                        let playerName = this.getPlayerName(playerId)
                        let hasFolded = this.getFoldedStatus(playerId)
                        let allIn = this.getAllInStatus(playerId)
                        let bet = this.getBet(playerId)
                        let hand = this.getHand(playerId)
                        let isCurrentPlayer = this.getCurrentPlayerStatus(playerId)
                        let playerReady = this.getPlayerReady(playerId)
                        return <div className={`${this.props.withinEndGameModal ? "opponent-cards-within-endgame-sizing-help" : ""} ${(isCurrentPlayer && !this.props.withinEndGameModal) ? "currentPlayerStyle" : "notCurrentPlayerStyle"}`}><Animated animationIn={!this.state.withinEndGameModal? "fadeInDown" : "fadeInRight"}
                                        animationOut="fadeOutUpBig"
                                        animationInDuration={1000}
                                        animationOutDuration={1000}
                                        animationInDelay={this.state.withinEndGameModal ? 0 : animationDelay}
                                        isVisible={true}>
                                    <h2 className={!this.state.withinEndGameModal ? "player-name-style": "player-name-style-endgame-modal"}>{playerName}</h2>
                                    <OpponentHand cards={!this.props.withinEndGameModal ? dummyHand : hand} chipsValue={chipsValue} hasFolded={hasFolded} allIn={allIn} numberOfOpponents={Object.keys(this.props.playerInfo).length.toString()} theme={this.props.theme} withinEndGameModal={this.state.withinEndGameModal} bet={bet} buyIn={this.props.buyIn} playerReady={playerReady}/>
                        </Animated></div>
                    }
                })}
            </div>
        )
    }
}

export default Opponents;
