import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import DisplayFiveCards from './DisplayFiveCards'
import Hand from './Hand'
import Opponents from '../UserComponents/Opponents'

function Winner(props) {
  console.log("in Winner component")
  let cards = props.fiveCardHand.map(card =>{
    let cardData = card.split("-")
    return {'rank': cardData[0], 'suit': cardData[1]}
  })
  let rawWinnerName = `${props.playerInfo[props.playerId]?.playerName || (props.myPlayerInfo?.playerName || "Kenny Rogers")}`;
  let winnerName = rawWinnerName.charAt(0).toUpperCase() + rawWinnerName.slice(1);
  let winnings = `$${props.winnings}`
  let handRank = ((props.handRank == "all others folded") ? ` - everyone else folded.` : ` with ${props.handRank}.`)
  return (
    <div>
      <div className="row">
        <div className="col align-left">
          <h2 className="modal-bold-text">{winnerName}</h2>
          <h2 className="modal-text"> won </h2>
          <h2 className="modal-bold-text">{winnings}</h2>
          <h2 className="modal-text">{handRank}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col align-left">
          <h2 className="modal-text">This was their best hand:</h2>
        </div>
      </div>
      <div className="row"><DisplayFiveCards cards={cards} theme={props.theme}/></div>
    </div>);
}
class HandOverFooter extends Component {

  constructor(props) {
    super(props)
    this.state = {
      disableBuyInButton: false,
      disableSitOutButton: false,
      disableJoinNextHandButton: false,
    }
  }

  render () {
    console.log("MY PLAYER CHIPS VALUE: " + (this.props.myPlayerInfo?.chipsValue || 0))
    console.log("MY PLAYER ID: " + this.props.myPlayerId)
    return(
      <div>
        {<Button variant="outline-dark" size="lg" className="endgame-modal-button" disabled={!this.props.isHost}
                onClick={() => this.props.startNewGame(this.props.tableId)}>{this.props.isHost ? "Start New Game" : "Waiting for host..."}</Button>}
        {this.props.lastChipTotal < this.props.bigBlind &&
          <Button variant="outline-success" size="lg" className="endgame-modal-button"
              disabled={this.state.disableBuyInButton}
              onClick={() => {
                this.props.buyBackIn(this.props.tableId, this.props.myPlayerId);
                this.setState({disableBuyInButton: true});
              }}>Buy Back In (${this.props.buyIn})</Button>}
        {this.props.lastChipTotal >= this.props.bigBlind && this.props.isActive == false &&
          <Button variant="outline-success" size="lg" className="endgame-modal-button"
              disabled={this.state.disableJoinNextHandButton}
              onClick={() => {
                this.setState({disableJoinNextHandButton: true, disableSitOutButton: false});
                this.props.buyBackIn(this.props.tableId, this.props.myPlayerId);
              }}>Join Next Hand</Button>}
        {this.props.isActive && <Button variant="outline-warning" size="lg" className="endgame-modal-button"
                 onClick={() => {
                  this.setState({disableSitOutButton: true, disableJoinNextHandButton: false});
                  this.props.leaveTable(false);
                   }}>Sit Out</Button>}
        {<Button variant="outline-danger" size="lg" className="endgame-modal-button"
                onClick={() => this.props.leaveTable(true)}>{this.props.isHost == false ? "Leave Table" : "Leave Table (WARNING: will kill table.)"}</Button>}
      </div>
    )
  }
}

class HandOverScreen extends Component {

    constructor(props) {
      super(props)
      this.state={
        sortedWinResult: {},
        theme: ""
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if(prevState.theme != nextProps.theme) {
        return {theme: nextProps.theme};
      }
      return null;
    }

    getWinnerModalBody() {
        let winnerString = ""
        if (this.props.gameOver == true) {
          var numWinners = Object.entries(this.props.winResult).length
          winnerString += (numWinners > 1) ? `There were ${numWinners.toString()} winners!\n\n` : `There was ${numWinners.toString()} winner!\n\n`
        }
        return winnerString;
      }

    sortedWinResult() {
      if(this.props.gameOver == true) {
         var winnerArray = [];
         for (var player in this.props.winResult) {
           winnerArray.push([player, this.props.winResult[player]])
         }
         return winnerArray.sort(function(a, b) {
           return b.winnings - a.winnings
         })
      } else { return []; }
    }


    render() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.gameOver}
                animation={true}
                style={{ opacity: 1, overflowY: "auto" }}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2 className="modal-title-small">Hand Summary</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h1 className="modal-main-header endgame-modal-main-header">Pot: <b>${this.props.totalPot}</b></h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <br/>
                        <div className="row">
                          <div className="col align-left">
                            <h2 className="modal-main-header endgame-modal-text-small-header bottom-border">{this.getWinnerModalBody()}</h2>
                          </div>
                        </div>
                        {this.sortedWinResult().map((winnerObject, index) => {
                            return <Winner
                                      fiveCardHand={winnerObject[1].fiveCardHand}
                                      count={index+1}
                                      playerId={winnerObject[0]}
                                      playerInfo={this.props.otherPlayers}
                                      myPlayerInfo={this.props.myPlayerInfo}
                                      winnings={winnerObject[1].winnings}
                                      handRank={winnerObject[1].handRank}
                                      theme={this.state.theme}
                                    />
                        })}
                        <br/>
                        <br/>
                        <div className="row">
                            <div className="col align-left">
                                <h3 className="modal-text">Community Cards:</h3>
                            </div>
                        </div>
                        <div className="row">
                          <DisplayFiveCards cards={this.props.cards} theme={this.state.theme}/>
                        </div>
                        <br/>
                      </div>
                      <div className="col-sm-6">
                        <br/>
                        <div className="row">
                            <div className="col align-left">
                                <h2 className="modal-main-header endgame-modal-text-small-header bottom-border">Opponents' Hands: </h2>
                            </div>
                        </div>
                        <Opponents order={this.props.order}
                            playerInfo={this.props.otherPlayers}
                            playerId={this.props.myPlayerId}
                            theme={this.state.theme}
                            withinEndGameModal={true}
                            playerList={this.props.playerList}/>
                        <br/>
                      </div>
                    </div>
                    <br/>
                    <br/>
                      <div className="row">
                          <div className="col align-left">
                              <h2 className="modal-main-header endgame-modal-text-small-header">You have <b className={`endgame-money-styling ${ this.props.chipsValue < this.props.buyIn ? "red" : "green"}`}>${this.props.lastChipTotal}</b> left in chips. {this.props.lastChipTotal <= this.props.bigBlind ? `Looks like you'll need to buy back in.` : ""}</h2>
                          </div>
                      </div>
                </Modal.Body>
                <Modal.Footer>
                  <HandOverFooter
                    isHost={this.props.isHost}
                    myPlayerInfo={this.props.myPlayerInfo}
                    myPlayerId={this.props.myPlayerId}
                    buyBackIn={this.props.buyBackIn}
                    startNewGame={this.props.startNewGame}
                    leaveTable={this.props.leaveTable}
                    tableId={this.props.tableId}
                    bigBlind={this.props.bigBlind}
                    buyIn={this.props.buyIn}
                    isActive={this.props.isActive}
                    lastChipTotal={this.props.lastChipTotal}
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default HandOverScreen;
