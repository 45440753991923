import React, { Component } from 'react'

export class ThemeSelector extends Component {

    constructor(props){
        super(props);
        this.state = {
          theme: ""
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
      if(nextProps.theme != state.theme) {
        return {theme: nextProps.theme};
      }
      return null;
    }

    componentDidMount = () => {
        this.setState({
          theme: ""
        })
    }

    render() {

        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <label className={`btn theme-button transparent-bg ${this.state.theme == "" ? "selected-theme" : ""}`}>
                <img src={require(`../img/cards/HH.png`)} className="card-button" />
                <input type="radio" name="options" id="option1" defaultChecked autoComplete="off" onClick={() =>
                    this.props.setTheme("")}/>
                <div className="theme-selector-label">Modern</div>
              </label>
              <label className={`btn theme-button transparent-bg ${this.state.theme == "retro" ? "selected-theme" : ""}`}>
                <img src={require(`../img/cards/HHretro.png`)} className="card-button" />
                <input type="radio" name="options" id="option2" autoComplete="off" onClick={() =>
                    this.props.setTheme("retro")}/>
                <div className="theme-selector-label theme-selector-label-retro">Retro</div>
              </label>
              <label className={`btn theme-button transparent-bg ${this.state.theme == "trippy" ? "selected-theme" : ""}`}>
                <img src={require(`../img/cards/HHtrippy.png`)} className="card-button" />
                <input type="radio" name="options" id="option3" autoComplete="off" onClick={() =>
                    this.props.setTheme("trippy")}/>
                <div className="theme-selector-label theme-selector-label-trippy">Trippy</div>
              </label>
            </div>
            )
    }
}

export default ThemeSelector;
