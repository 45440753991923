import React, { Component } from 'react';
import Hand from './Hand';
import Opponents from '../UserComponents/Opponents';
import PlayerList from '../UserComponents/PlayerList';
import PlayerSettings from '../UserComponents/PlayerSettings';
import PlayerTurn from '../UserComponents/PlayerTurn';
import GenericInfoModal from '../UserComponents/GenericInfoModal'
import JoinCode from './JoinCode';
import Actions from './Actions';
import River from './River';
import GameStats from './GameStats';
import SmackTalk from './SmackTalk';
import Pot from './Pot';
import {Animated} from "react-animated-css";
import '../App.css';
import '../Table.css';
import 'bootstrap/dist/css/bootstrap.css';
import HandOverScreen from './HandOverScreen'
import Cookies from 'universal-cookie';
import ReactLoading from "react-loading";
import yourTurnSound from '../sounds/yourturn.mp3';

class GameScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
            chatLog: [],
            gameId: "",
            isActive: false,
            lastChipTotal: 0,
            playerList: [],
            cards: [],
            order: [],
            isCurrentPlayer: false,
            myPlayerInfo: {},
            otherPlayers: {},
            inGame: false,
            tableStatus: "",
            gameOver: false,
            theme: "",
            isHost: false,
            hostUserId: null
        }
        this.audio = new Audio(yourTurnSound);
    }

    componentDidMount = () => {
        this.setPlayerAndTableCookies(this.props.playerId, this.props.tableId)
        let cookies = new Cookies()
        let theme = cookies.get('theme') || "";
        this.setState({
            chatLog: [],
            cards: [
                {rank: "H", suit: "H"},
                {rank: "H", suit: "H"},
                {rank: "H", suit: "H"},
                {rank: "H", suit: "H"},
                {rank: "H", suit: "H"},
            ],
            hand: [
                {rank: "H", suit: "H"},
                {rank: "H", suit: "H"},
            ],
            isCurrentPlayer: false,
            isActive: false,
            lastChipTotal: 0,
            myPlayerInfo: {},
            otherPlayers: {},
            order: [],
            gameId: "",
            chipsValue: 0,
            inGame: false,
            tableStatus: "",
            gameOver: false,
            theme: theme,
            rejoining: this.props.rejoining,
            disabledActionButtons: false,
        });
        this.connectToGameStateSocket(this.props.tableId, this.props.playerId);
        this.connectToTableStateSocket(this.props.tableId, this.props.playerId);
    };

    setPlayerAndTableCookies(playerId, tableId) {
        let cookies = new Cookies()
        let minutes = 60
        let d = new Date();
        d.setTime(d.getTime() + (minutes*60*1000));
        cookies.set("playerId", playerId, {path: "/", expires: d});
        cookies.set("tableId", tableId, {path: "/", expires: d});
      };

    timeout = 600; // Initial timeout duration as a class variable

    connectToTableStateSocket(tableId, playerId) {
        const ws = new WebSocket('wss://r32y8aj8kf.execute-api.us-east-1.amazonaws.com/stage')
        let that = this; // cache the this
        let connectInterval;

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");
            this.setState({ ws: ws });
            that.timeout = 600; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection

            let data = '{"action":"linkPlayerAndTable", "playerId":"' +
                playerId + '", "tableId":"' + tableId + '"}'
            console.log(data);
            try {
                ws.send(data); //send data to the server
            } catch (error) {
                console.log(error) // catch error
            }
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );
            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        ws.onmessage = e => {
            console.log(e.data);
            let tableStateData = JSON.parse(e.data);
            console.log(tableStateData.chatLog);
            // This is an example of how data can be read from serverside posts to web socket connection
            this.setState({
                chatLog: tableStateData.chatLog,
                isActive: tableStateData.isActive,
                lastChipTotal: tableStateData.lastChipTotal,
                playerList: tableStateData.playerList,
                tableStatus: tableStateData.tableStatus,
                buyIn: tableStateData.buyIn,
                bigBlind: tableStateData.bigBlind,
                hostUserId: tableStateData.hostUserId,
                maxPlayers: tableStateData.maxPlayers,
                tableName: tableStateData.tableName
            });
            if (this.state.hostUserId === this.props.userId) {
                this.setState({isHost: true})
                let cookies = new Cookies();
                let minutes = 60
                let d = new Date();
                d.setTime(d.getTime() + (minutes*60*1000));
                cookies.set("userId", this.props.userId, {path: "/", expires: d});
            }
            let youAreKicked = false;
            if (this.state.playerList?.length || 0 > 0) {
              youAreKicked = true
            }
            for (const player of this.state.playerList) {
              if (player.playerId == this.props.playerId) {
                youAreKicked = false;
                break;
              }
            }
            if (youAreKicked) {
              console.log("You've been kicked from the table.")
              this.props.gotKicked()
            }
        };

        if (this.state.tableStatus == "waiting") {
            this.setState({rejoining: false})
        }

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
            ws.close();
        };
    };

    connectToGameStateSocket(tableId, playerId) {
        const ws = new WebSocket('wss://ha05548ugf.execute-api.us-east-1.amazonaws.com/dev')
        let that = this; // cache the this
        let connectInterval;

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");
            this.setState({ ws: ws });
            that.timeout = 600; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection

            let data = '{"action":"linkGame", "playerId":"' +
                playerId + '", "tableId":"' + tableId + '"}'
            console.log(data);
            try {
                ws.send(data); //send data to the server
            } catch (error) {
                console.log(error) // catch error
            }
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );
            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        ws.onmessage = e => {
            // This is an example of how data can be read from serverside posts to web socket connection
            this.setPlayerAndTableCookies(this.props.playerId, this.props.tableId)
            console.log(e.data);
            let gameStateData = JSON.parse(e.data)

            let myPlayerInfo = gameStateData.myPlayerInfo

            let hand = (myPlayerInfo == null) ? ["H-H", "H-H"] : gameStateData.myPlayerInfo.hand
            let cardOne = hand[0].split("-")
            let cardTwo = hand[1].split("-")

            let flopOne = gameStateData.flop1.split("-")
            let flopTwo = gameStateData.flop2.split("-")
            let flopThree = gameStateData.flop3.split("-")
            let turnOne = gameStateData.turn.split("-")
            let riverOne = gameStateData.river.split("-")

            let playerInfo = gameStateData.playerInfo
            let order = gameStateData.playerOrder
            let gameOver = gameStateData.hasOwnProperty('winResult');
            let winResult = gameOver ? gameStateData.winResult : {}
            if ((myPlayerInfo?.isCurrentPlayer || false) && (this.state.myPlayerInfo?.isCurrentPlayer == false)) {
              var promise = this.audio.play();

              if (promise !== undefined) {
                promise.catch(error => {
                    console.log("fuck safari")
                    console.log(error)
                  });
              }
            };
            this.setState(
                {
                    myPlayerInfo: gameStateData.myPlayerInfo,
                    currentTableBet: gameStateData.currentTableBet,
                    minRaiseAmount: gameStateData.minRaiseAmount,
                    gameId: gameStateData.gameId,
                    hand: [
                        {rank: cardOne[0], suit: cardOne[1]},
                        {rank: cardTwo[0], suit: cardTwo[1]},
                    ],
                    chipsValue: gameStateData.myPlayerInfo?.chipsValue || 0,
                    cards: [
                        {rank: flopOne[0], suit: flopOne[1]},
                        {rank: flopTwo[0], suit: flopTwo[1]},
                        {rank: flopThree[0], suit: flopThree[1]},
                        {rank: turnOne[0], suit: turnOne[1]},
                        {rank: riverOne[0], suit: riverOne[1]},
                    ],
                    otherPlayers: playerInfo,
                    order: order,
                    round: gameStateData.round,
                    totalPot: gameStateData.totalPot,
                    gameOver: gameOver,
                    winResult: winResult,
                    rejoining: false,
                    disabledActionButtons: false,
                });
        };
        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
            ws.close();
        };
    };

    onStartGame = (tableId) => {
        console.log(this.state)
        if (this.state.playerList.length < 2) {
            console.log("Not enough players to start!")
            this.setState({showNotEnoughPlayersModal: true})
        } else {
            console.log("this is the table ID:")
            console.log(tableId)
            fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/game/start', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tableId: tableId,
                })
            })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            // Catch any errors we hit and update the app
            .catch(error => this.setState({hasHostTableError: true, atTable: false}));
        }
    };

    buyBackIn = (tableId, playerId) => {
        console.log(tableId)
        console.log(playerId)
        fetch('https://0rzgjhy7og.execute-api.us-east-1.amazonaws.com/dev/player', {
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({'tableId': tableId, 'playerId': playerId})
      })
          // We get the API response and receive data in JSON format...
          .then(response => response.json())
          // ...then we update the users state
          .then(data => {
              console.log(data);
              let newMyPlayerInfo = this.state.myPlayerInfo;
              newMyPlayerInfo['chipsValue'] = data.totalChips;
              this.setState({myPlayerInfo: newMyPlayerInfo})
          })
          // Catch any errors we hit and update the app
          .catch(error => console.log(error));
    }

    closeModal = () => {
      this.setState({showNotEnoughPlayersModal: false});
    }

    disableActionButtons = () => {
        this.setState({disabledActionButtons: true});
    }

    enableActionButtons = () => {
        this.setState({disabledActionButtons: false});
    }

    setTheme = (themeName) => {
      this.setState({theme: themeName});
      let cookies = new Cookies()
      let minutes = 60
      let d = new Date();
      d.setTime(d.getTime() + (minutes*60*1000));
      cookies.set("theme", themeName, {path: "/", expires: d});
    }

    render(){
        if (this.state.tableStatus == "waiting") {
            return(
            <div className={`row ${this.props.isMobile ? "bottom-padding-more" : ""}`}>
                    <div className="container col-sm-4">
                        <JoinCode tableId={this.props.tableId}/>
                    </div>
                    <div className="container col-sm-4 mobile-vertical-padding">
                        <PlayerList playerList={this.state.playerList}
                            tableId={this.props.tableId}
                            onStartGame={this.onStartGame}
                            isHost={this.state.isHost}
                            kickPlayer={this.props.kickPlayer}
                            myPlayerId={this.props.playerId}/>
                    </div>
                    <div className="container col-sm-4 mobile-vertical-padding">
                        <PlayerSettings tableId={this.props.tableId} maxPlayers={this.state.maxPlayers} bigBlind={this.state.bigBlind} buyIn={this.state.buyIn} tableName={this.state.tableName} hostUserId={this.state.hostUserId} isHost={this.state.isHost} theme={this.state.theme} setTheme={this.setTheme}/>
                    </div>
                    <GenericInfoModal showModal={this.state.showNotEnoughPlayersModal} modalHeader={"You need at least 2 players in order to start a game."} closeModal={this.closeModal}/>
            </div>
            )
        } else if (this.state.tableStatus == "playing" || this.state.tableStatus == "finished") {
            return(
            <div className={this.props.isMobile ? "bottom-padding-more" : ""}>
                <div className={`main-game ${this.state.gameOver ? "game-over-main":""}`}>
                    <Animated animationIn="zoomIn" animationOut="bounceOut" animationInDuration={1000} animationOutDuration={1000} animationInDelay={2000} isVisible={true}>
                        <div className="row">
                            <div className="col no-right-padding">
                                <JoinCode tableId={this.props.tableId} tableStatus={this.state.tableStatus}/>
                            </div>
                            <div className="col no-left-padding">
                                <PlayerTurn playerInfo={this.state.otherPlayers} order={this.state.order} myPlayerInfo={this.state.myPlayerInfo}/>
                            </div>
                        </div>
                    </Animated>
                    <div className="bottom-padding">
                        <Opponents order={this.state.order}
                            playerInfo={this.state.otherPlayers}
                            playerId={this.props.playerId}
                            buyIn={this.state.buyIn}
                            theme={this.state.theme}/>
                    </div>
                    {!this.props.isMobile && <div className="row no-gutters bottom-padding">
                        <div className="col-2 no-gutters">
                            <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                <GameStats currentRound={this.state.round} totalPot={this.state.totalPot} currentTableBet={this.state.currentTableBet} myPlayerInfo={this.state.myPlayerInfo} otherPlayers={this.state.otherPlayers}/>
                            </Animated>
                        </div>
                        <div className="col-8 no-gutters">
                            <Animated animationIn="fadeInRight" animationOut="fadeOutLeftBig" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                <River cards={this.state.cards} theme={this.state.theme}/>
                            </Animated>
                        </div>
                        <div className="col-2 no-gutters">
                            <Animated animationIn="fadeInRight" animationOut="fadeOutLeftBig" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                <Pot potValue={this.state.totalPot} bigBlind={this.state.bigBlind} buyIn={this.state.buyIn}/>
                            </Animated>
                        </div>
                    </div>}
                    {this.props.isMobile && <div className="row no-gutters">
                        <div className="col-9 no-gutters">
                            <Animated animationIn="fadeInRight" animationOut="fadeOutLeftBig" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                <River cards={this.state.cards} theme={this.state.theme}/>
                            </Animated>
                        </div>
                        <div className="col-3 no-gutters">
                            <Animated animationIn="fadeInRight" animationOut="fadeOutLeftBig" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                <Pot potValue={this.state.totalPot} bigBlind={this.state.bigBlind} buyIn={this.state.buyIn}/>
                            </Animated>
                        </div>
                    </div>}
                    <div>
                        <Animated animationIn="fadeInUp" animationOut="fadeOutDownBig" animationInDuration={1000} animationInDelay={1000} animationOutDuration={1000} isVisible={true}>
                            {!this.props.isMobile && <Hand cards={this.state.hand} chipsValue={this.state.chipsValue} isActive={this.state.isActive}
                                  buyIn={this.state.buyIn} myPlayerInfo={this.state.myPlayerInfo}/>}
                            {this.props.isMobile &&
                              <div className="row no-gutters bottom-padding">
                                <div className="col-4 no-gutters">
                                    <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                        <GameStats currentRound={this.state.round} totalPot={this.state.totalPot} currentTableBet={this.state.currentTableBet} myPlayerInfo={this.state.myPlayerInfo} otherPlayers={this.state.otherPlayers}/>
                                    </Animated>
                                </div>
                                <div className="col-8 no-gutters">
                                    <Animated animationIn="fadeInUp" animationOut="fadeOutDownBig" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                    <Hand cards={this.state.hand} chipsValue={this.state.chipsValue} isActive={this.state.isActive}
                                          buyIn={this.state.buyIn} myPlayerInfo={this.state.myPlayerInfo}/>
                                    </Animated>
                                </div>
                              </div>
                            }
                        </Animated>
                        <Actions myPlayerInfo={this.state.myPlayerInfo}
                                    gameId={this.state.gameId}
                                    playerId={this.props.playerId}
                                    currentTableBet={this.state.currentTableBet}
                                    myBet={this.state.myPlayerInfo?.bet || 0}
                                    minRaiseAmount={this.state.minRaiseAmount}
                                    disableActionButtons={this.disableActionButtons}
                                    enableActionButtons={this.enableActionButtons}
                                    disabledButtons={this.state.disabledActionButtons}/>
                    </div>
                </div>
                <div>
                    <SmackTalk
                        messageList={this.state.chatLog}
                        me={this.state.myPlayerInfo?.playerName || "Player"}
                        tableId={this.props.tableId}
                        chatOpen={this.props.chatOpen}/>
                </div>
                <HandOverScreen
                    gameOver={this.state.gameOver}
                    totalPot={this.state.totalPot}
                    cards={this.state.cards}
                    winResult={this.state.winResult}
                    tableId={this.props.tableId}
                    startNewGame={this.onStartGame}
                    otherPlayers={this.state.otherPlayers}
                    myPlayerInfo={this.state.myPlayerInfo}
                    myPlayerId={this.props.playerId}
                    theme={this.state.theme}
                    buyBackIn={this.buyBackIn}
                    leaveTable={this.props.leaveTable}
                    isHost={this.state.isHost}
                    bigBlind={this.state.bigBlind}
                    order={this.state.order}
                    chipsValue={this.state.chipsValue}
                    buyIn={this.state.buyIn}
                    isActive={this.state.isActive}
                    lastChipTotal={this.state.lastChipTotal}
                    playerList={this.state.playerList}
                    />
            </div>
            )
        } else {
            return(
                <div className="row">
                    <div className="container col-sm-4">
                        <JoinCode tableId={this.props.tableId}/><br/><br/><br/>
                    </div>
                    <div className="container col-sm-4 d-flex justify-content-center">
                        <ReactLoading type={"bars"} color={"white"} />
                    </div>
                    <div className="container col-sm-4"></div>
                </div>
            )
        }
    }
}

export default GameScreen;
