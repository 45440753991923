import React, { Component } from 'react'
import blackChip from '../img/black_chip.png';
import fireGif from '../img/fire2.gif'; /*credits: https://gifimage.net/fire-animated-gif-transparent-11/*/
import {Animated} from "react-animated-css";

const imageBox = {
    position: 'relative',
    display: 'inline-block' /* Make the width of box same as image */
};

const imageText = {
    'position': 'absolute',
    'z-index': '999',
    'margin': '0 auto',
    'left': '0',
    'right': '0',
    'top': '10%', /* Adjust this value to move the positioned div up and down */
    'text-align': 'center',
    'width': '60%', /* Set the width of the positioned div */
};

const handStyle = {
    display: 'grid inline-block',
    backgroundColor: 'transparent',
    position: 'center',
    padding: '8px',
    class: 'border-dark'
};

const chipImgStyle = {
    height: '7%',
    width: '7%',
};

const hideChip = {
  height: '0%',
  width: '0%',
}

export default class OpponentHand extends Component {

    constructor(props) {
      super(props)
      this.state = {
        theme: ""
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if(prevState.theme != nextProps.theme) {
        return {theme: nextProps.theme};
      }
      return null;
    }

    render() {
        const cardOnePath =  require('../img/cards/' + this.props.cards[0].rank + this.props.cards[0].suit + `${this.state.theme != undefined && this.props.cards[0].rank == 'H' ? this.state.theme : ""}` + '.png');
        const cardTwoPath =  require('../img/cards/' + this.props.cards[1].rank + this.props.cards[1].suit + `${this.state.theme != undefined && this.props.cards[1].rank == 'H' ? this.state.theme : ""}` + '.png');

        return (
            <div>
                <div className={imageBox}>
                    <div className="align-center"><h2 className={`money-styling ${ this.props.chipsValue < this.props.buyIn ? "red" : "green"}`}>${this.props.chipsValue}</h2></div>
                </div>
                <div style={handStyle} className="relative-position">
                    <div className={`${this.props.hasFolded ? "folded" : ""} ${this.props.allIn && !this.props.withinEndGameModal ? "all-in" : ""}`}>
                        <img className={`opponentCardImgStyleAll ${this.props.withinEndGameModal ? "big-opponent-cards" : "opponentCardImgStyle-" + this.props.numberOfOpponents}`} src={cardOnePath} />
                        <img className={`opponentCardImgStyleAll ${this.props.withinEndGameModal ? "big-opponent-cards" : "opponentCardImgStyle-" + this.props.numberOfOpponents}`} src={cardTwoPath} />
                        <img src={blackChip} alt="chipsVal" style={!this.props.withinEndGameModal ? chipImgStyle : hideChip}/>
                        <img src={fireGif} className={`all-in-overlay all-in-overlay-${this.props.numberOfOpponents} ${this.props.allIn && !this.props.withinEndGameModal ? "opacity-1" : "opacity-0"}`} />
                    </div>
                    <div>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} animationInDelay={1000} isVisible={this.props.hasFolded}>
                            <p className="folded-text">FOLDED</p>
                        </Animated>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} animationInDelay={1000} isVisible={this.props.allIn && !this.props.withinEndGameModal}>
                            <p className="all-in-text">ALL IN</p>
                        </Animated>
                    </div>
                </div>
                <div className={`imageBox ${this.props.withinEndGameModal ? "" : "display-none"}`}>
                    <div className="align-center"><h4 className="player-status-text">{this.props.playerReady ? "READY" : "SITTING OUT"}</h4></div>
                </div>
            </div>
        )
    }
}
