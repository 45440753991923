import React, { Component } from 'react';
import {Animated} from "react-animated-css";


class StartGame extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount = () => {
        console.log("in playerlist is Host? " + this.props.isHost);
    };

    render() {
        if (this.props.isHost) {
            return (
                <div>
                    <button type="button" className="btn btn-outline-light btn-xlg white"
                            onClick={() =>
                                this.props.onStartGame(this.props.tableId)}>
                        Start Game!
                    </button>
                </div>
            )
        } else {
            return(
              <div>
                  <button type="button" className="btn btn-outline-light btn-xlg white" disabled={true}>
                      Waiting for host...
                  </button>
              </div>
            )
        }

    }
}

export default StartGame;
