import React, { Component } from 'react'

export class PlayerTurn extends Component {

    constructor(props){
        super(props);
    }

    state = {
      currentPlayer: "",
      myTurn: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      let state = {}
      nextProps.order.map(playerId =>{
        if(playerId in nextProps.playerInfo) {
          if(nextProps.playerInfo[playerId]['isCurrentPlayer'] == true){
            state = {currentPlayer: nextProps.playerInfo[playerId]['playerName'], myTurn: false};
          }
        } else {
          if(nextProps.myPlayerInfo['isCurrentPlayer'] == true){
            state = {currentPlayer: nextProps.myPlayerInfo['playerName'], myTurn: true};
          }
        }
      })
      return state;
    }

    render() {
        return (
            <div>
                <h1 className="header-style align-right normal-font-weight">
                    {this.state.myTurn ? "Your turn!" : `${this.state.currentPlayer}'s Turn!`}
                </h1>
            </div>
        )
    }
}

export default PlayerTurn;
