import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class GenericInfoModal extends Component {

    constructor(props) {
      super(props)
      this.state={
        showModal: false
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if(nextProps.showModal != prevState.showModal) {
        return {showModal: nextProps.showModal};
      }
      return null;
    }

    getHeader() {
      if(this.props.modalHeader) {
        return (<h1 className="modal-main-header">{this.props.modalHeader || "Information"}</h1>);
      }
    }

    getBody() {
      if(this.props.modalBody) {
        return (
          <div className="row">
              <div className="col align-left">
                  <h2 className="modal-text-bold">{this.props.modalBody || "Details" }</h2>
              </div>
          </div>
        )
      }
    }

    render() {return (
      <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          animation={true}
          style={{ opacity: 1 }}
      >
          <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                  <h1 className="modal-title-small">{this.props.modalTitle || "Hey there!"}</h1>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.getHeader()}
              {this.getBody()}
          </Modal.Body>
          <Modal.Footer>
              <Button variant="outline-dark" size="lg" className="endgame-modal-button" onClick={() => this.props.closeModal()}>Close</Button>
          </Modal.Footer>
      </Modal>
    )}

}

export default GenericInfoModal;
