import React, { Component } from 'react';
import chips from '../img/chip_stack.png';
import Hand from '../GameComponents/Hand';
import StartGame from '../GameComponents/StartGame';
import {Animated} from "react-animated-css";

const playerListStyle = {
    padding: '15px',
    display: 'grid inline-block',
    fontFamily: "Gill Sans",
    fontWeight: 'bold',
    color: 'white',
    textShadow: 'rgba(0,0,0,1.0) 0px 1px 1px'
};

class PlayerList extends Component {

    constructor(props){
        super(props);
    }
    componentDidMount = () => {
        console.log("in playerlist is Host? " + this.props.isHost);
    }

    render() {
        return (
            <div>
                <h1 className="header-style">
                    PLAYER LIST:
                </h1>
                <div style={playerListStyle}>
                    {this.props.playerList.map(player =>{
                        return (<Animated animationIn="fadeInUp" animationOut="fadeOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}><div key={player.playerId} className="row">
                                  <div className={this.props.isHost && (player.playerId != this.props.myPlayerId) ? "col-6" : "col-12"}><h2 key={player.playerId} className="inline">{player.playerName}</h2></div>
                                  <div className={this.props.isHost && (player.playerId != this.props.myPlayerId) ? "col-6" : "display-none"}><button type="button" className={`btn btn-outline-light btn-lg btn-block btn-bold inline float-left ${this.props.isHost ? "" : "display-none"}`} onClick={() => this.props.kickPlayer(player.playerId)}>KICK</button></div>
                                </div></Animated>)
                    })}
                </div><br/>
                <StartGame tableId={this.props.tableId}
                    onStartGame={this.props.onStartGame}
                    isHost={this.props.isHost}/>
            </div>
        )
    }
}

export default PlayerList;
